const fileInputs = document.querySelectorAll('.form-control-file');
if (fileInputs.length > 0) {
    const MaxClientBodySize = 1024 * 1024; // 1MB
    const allowedMimeTypes = ['image/jpeg', 'image/png', 'image/webp', 'image/svg+xml'];
    const renderError = (elm, message) => {
        elm.value = '';
        elm.classList.add("is-invalid");

        let feedback = document.createElement("div", {});
        feedback.classList.add("invalid-feedback");
        feedback.innerText = message;

        elm.after(feedback);
    };

    const resetErrors = (elm) => {
        elm.classList.remove("is-invalid");
        let siblings = [];
        let nextSibling = elm.nextElementSibling;

        while(nextSibling) {
            siblings.push(nextSibling);
            nextSibling = nextSibling.nextElementSibling;
        }

        siblings.forEach(elm => elm.remove());
    };

    fileInputs.forEach(fileInput => {
        fileInput.addEventListener('change', (e) => {
            const elm = e.target;
            const file = e.target.files[0];

            resetErrors(elm);

            if(!allowedMimeTypes.includes(file.type)) {
                renderError(elm, 'Allowed file types: jpeg, png, webp, svg');
            }

            if(file.size > MaxClientBodySize) {
                renderError(elm, `Max file size is: ${MaxClientBodySize / 1024 / 1024}MB`);
            }
        });
    });
}
