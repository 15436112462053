import {ServerTable} from 'vue-tables-2';
import 'vue2-datepicker/locale/ru';
import 'vue2-datepicker/locale/kk';
import Select2 from 'v-select2-component';

/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require('./bootstrap');
require('./handlers');

window.Vue = require('vue').default;
window.Chart = require('chart.js/auto').default;
window.DatePicker = require('vue2-datepicker').default;
window.IMask = require('imask').default;
window.Select2 = Select2;

Vue.use(ServerTable, {}, false, 'bootstrap4', {});